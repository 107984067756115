import React from "react";
import Head from "../components/head";

const NotFoundPage = () => (
  <>
    <Head
      title="Page Not Found"
      dis="NOT FOUND - You just hit a route that doesn't exist... the sadness."
    />

    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </>
);

export default NotFoundPage;
